@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

html {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}

